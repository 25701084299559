// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-staff-directory-jsx": () => import("./../../../src/pages/staff-directory.jsx" /* webpackChunkName: "component---src-pages-staff-directory-jsx" */),
  "component---src-pages-talent-submissions-jsx": () => import("./../../../src/pages/talent-submissions.jsx" /* webpackChunkName: "component---src-pages-talent-submissions-jsx" */),
  "component---src-pages-voice-reels-demos-jsx": () => import("./../../../src/pages/voice-reels-demos.jsx" /* webpackChunkName: "component---src-pages-voice-reels-demos-jsx" */)
}

