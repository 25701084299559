import {
  TOGGLE_NAV,
  NEWS_HAS_ERRORED,
  NEWS_LOADED_SUCCESSFULLY,
  NEWS_IS_LOADING,
  TOGGLE_DIALOG,
} from './actions'

const initialState = {
  dialogState: false,
  navState: false,
  loading: false,
  newsPosts: [],
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DIALOG:
      return {
        ...state,
        dialogState: !state.dialogState,
      }
    case TOGGLE_NAV:
      return {
        ...state,
        navState: !state.navState,
      }
    case NEWS_IS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case NEWS_HAS_ERRORED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case NEWS_LOADED_SUCCESSFULLY:
      return {
        ...state,
        loading: false,
        newsPosts: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
