import React from "react";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Provider } from "react-redux";

import createStore from "./src/store/createStore";

Bugsnag.start({
  apiKey: 'a8f416eb576b3869b19cab74cbdeaa38',
  plugins: [new BugsnagPluginReact(React)]
})

const ErrorBoundary = Bugsnag.getPlugin('react');
const store = createStore();

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => (
  <Provider store={store}>
    <ErrorBoundary>
      {element}
    </ErrorBoundary>
  </Provider>
);
