import axios from 'axios'

export const TOGGLE_NAV = 'TOGGLE_NAV'
export const toggleNav = () => ({
  type: TOGGLE_NAV,
})

export const NEWS_IS_LOADING = 'NEWS_IS_LOADING'
export const newsIsLoading = () => ({
  type: NEWS_IS_LOADING,
})

export const NEWS_LOADED_SUCCESSFULLY = 'NEWS_LOADED_SUCCESSFULLY'
export const newsLoadedSuccessfully = payload => ({
  type: NEWS_LOADED_SUCCESSFULLY,
  payload,
})

export const NEWS_HAS_ERRORED = 'NEWS_HAS_ERRORED'
export const newsHasErrored = payload => ({
  type: NEWS_HAS_ERRORED,
  payload,
})

export const TOGGLE_DIALOG = 'TOGGLE_DIALOG'
export const toggleDialog = () => ({
  type: TOGGLE_DIALOG,
})
export const fetchNews = () => {
  return (dispatch) => {
    dispatch(newsIsLoading())
    axios
      .get('https://admin.ncatalent.com/wp-json/wp/v2/posts/')
      .then(response => {
        dispatch(newsLoadedSuccessfully(response.data))
      })
      .catch(err => dispatch(newsHasErrored(err)))
  }
}
