import { ADD_TRACK, TOGGLE_PLAYLIST, REMOVE_TRACK, CLEAR_PLAYLIST } from './actions'

const initialState = {
  list: [],
  played: [],
  playlistState: false,
}

export default (state = initialState, action) => {
  switch (action.type) {

  case ADD_TRACK:
    if (state.list.find(e => e === action.payload) !== undefined || state.list.length >= 5) {
      return state
    }
    return Object.assign({}, state, {
      list: [
        ...state.list,
        action.payload
      ]
    })
  case REMOVE_TRACK:
    var index = state.list.indexOf(action.payload);

    return {
      ...state,
      list: state.list.slice(0, index).concat(state.list.slice(index + 1)),
    }
  case TOGGLE_PLAYLIST:
    return Object.assign({}, state, {
      playlistState: !state.playlistState
    })
  case CLEAR_PLAYLIST:
    return Object.assign({}, state, {
      list: [],
      playlistState: false
    })
  default:
    return state
  }
}
