import {
  TALENTS_FETCHED_SUCCESSFULLY,
  TALENTS_HAVE_ERRORED,
  TALENTS_ARE_LOADING,

  SEARCH_FETCHED_SUCCESSFULLY,
  SEARCH_IN_PROGRESS,
  SEARCH_ERRORED,
  
  SET_LETTER_SEARCH_STATUS,
  SET_DISPLAY_LIST,
  SET_CURRENT_CAT,
  SET_CAT_OPTIONS,
  SET_CURRENT_GEN,
  SET_CATEGORIES,

  SORT_BY_LETTER,
  FIND_BY_SEARCH,
  CLEAR_FILTER,
} from './actionTypes'

const allCat = { value: '', label: 'All Categories' }
const allGen = { value: '', label: 'All Gender' }

const initialState = {
  loading: true,
  error: null,
  list: {},
  categories: [],
  categoryOptions: [
    allCat,
  ],
  currentCategoryOption: allCat,
  genderOptions: [
    allGen,
    { value: 'F', label: 'Female' },
    { value: 'M', label: 'Male' },
  ],
  currentGenderOption: allGen,
  sortLetter: 'a',
  lookup: '',
  letterSorterStatus: true,
  displayedList: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
  
  case CLEAR_FILTER:
    return {
      ...state,
      lookup: '',
      letterSorterStatus: true,
      currentCategoryOption: allCat,
      currentGenderOption: allGen,
      sortLetter: 'a',
    }
  case SET_DISPLAY_LIST:
    return {
      ...state,
      displayedList: {
        ...action.payload
      }
    }
  case SET_CATEGORIES:
    return {
      ...state,
      categories: action.payload,
    }
  case SET_CURRENT_CAT:
    return {
      ...state,
      currentCategoryOption: action.payload,
      letterSorterStatus: (action.payload.value !== '' || state.currentGenderOption !== '') ? false : true,
    }
  case SET_CAT_OPTIONS:
    return {
      ...state,
      categoryOptions: [
        ...state.categoryOptions,
        ...action.payload,
      ]
    }
  case SET_CURRENT_GEN:
    return {
      ...state,
      currentGenderOption: action.payload,
      letterSorterStatus: (action.payload.value !== '' || state.currentCategoryOption.value !== '') ? false : true,
    }
  case SET_LETTER_SEARCH_STATUS:
    return {
      ...state,
      letterSorterStatus: action.payload,
    }
  case SORT_BY_LETTER:
    return {
      ...state,
      sortLetter: action.payload,
      currentCategoryOption: allCat,
    }
  case FIND_BY_SEARCH:
    return {
      ...state,
      lookup: action.payload,
      letterSorterStatus: false,
    }

  case SEARCH_IN_PROGRESS:
    return {
      ...state,
      loading: true,
    }
  case SEARCH_ERRORED:
    return {
      ...state,
      error: action.payload.error,
    }
  case SEARCH_FETCHED_SUCCESSFULLY:
    return {
      ...state,
      loading: false,
      error: null,
      displayList: action.payload,
    }
  case TALENTS_ARE_LOADING:
    return {
      ...state,
      loading: true,
    }
  case TALENTS_HAVE_ERRORED:
    return {
      ...state,
      loading: false,
      error: action.payload.error,
    }
  case TALENTS_FETCHED_SUCCESSFULLY:
    return {
      ...state,
      loading: false,
      error: null,
      list: action.payload,
    }
    
  default:
    return state;
  }
}
