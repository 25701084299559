import {
  NEXT_PAGE,
  PREV_PAGE,
  SORT_BY_LETTER,
} from './actions'

const initialState = {
  pageItems: {},
  itemsPerPage: 8,
  currentPage: 0,
  pageOffset: 0,
}


export default (state = initialState, action) => {
  switch (action.type) {
  
  case NEXT_PAGE:
    const {currentPage, pageOffset, itemsPerPage} = state;
    const newOffset = pageOffset + itemsPerPage

    return Object.assign({}, state, {
      currentPage: currentPage + 1,
      pageOffset: newOffset,
      pageItems: Object.values(action.payload).slice(pageOffset, newOffset),
    })
  case PREV_PAGE:
    return Object.assign({}, state, {
      currentPage: state.currentPage = state.currentPage - 1,
      pageItems: action.payload
    })
  case SORT_BY_LETTER:
    
    return {
      ...state,
    }

  default:
    return state
  }
}
