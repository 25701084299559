import { 
  createStore as reduxCreateStore,
  combineReducers,
  applyMiddleware,
  compose
} from 'redux'
import thunk from 'redux-thunk'

import allVoiceTalents from './voice-demos/reducer'
import pagination from './pagination/reducer'
import audioPlayer from './audioPlayer/reducer'
import playlist from './playlist/reducer'
import site from './site/reducer'

const reducer = combineReducers({
  allVoiceTalents,
  pagination,
  audioPlayer,
  playlist,
  site
})

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
  // other store enhancers if any
);
const store = () => reduxCreateStore(reducer, enhancer);

export default store