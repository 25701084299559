import {
  LOADING,
  READY,
  PLAYPAUSE,
  PLAY,
  STOP,
  UPDATE_DURATION,
  UPDATE_CURRENT_TIME,
  CLOSEPLAYER,
  SET_VOLUME,
} from './actionTypes'

const initialState = {
  currentAudioID: null,
  isLoading: true,
  isPlaying: false,
  progress: 0,
  duration: 0,
  currentTime: 0,
  mainPlayerState: false,
  volume: 50,
}

export default (state = initialState, action) => {
  const shouldUpdate = action.payload === state.currentAudioID 
    || action.payload === undefined
  
  switch (action.type) {
  
  case LOADING:
    return {
      ...state,
      isLoading: true,
    }
  case READY:
    return {
      ...state,
      isLoading: false,
    }
  case PLAY:
    return {
      ...state,
      isPlaying: true,
      mainPlayerState: true,
    }
  case STOP:
    return {
      ...state,
      isPlaying: false,
    }
  case UPDATE_CURRENT_TIME:
    return {
      ...state,
      currentTime: action.payload,
    }
  case UPDATE_DURATION:
    return {
      ...state,
      duration: action.payload,
    }
  case PLAYPAUSE:
    const isPlaying = shouldUpdate ? !state.isPlaying : true
    const currentAudioID = shouldUpdate ? state.currentAudioID : action.payload

    return {
      ...state,
      currentAudioID,
      isPlaying,
    }
  case CLOSEPLAYER: {
    return {
      ...state,
      mainPlayerState: false,
      isPlaying: false
    }
  }
  case SET_VOLUME: {
    return {
      ...state,
      volume: action.payload
    }
  }
  default:
    return state
  }
}
