export const SET_DISPLAY_LIST = 'SET_LIST'
export const SET_CAT_OPTIONS = 'SET_CAT_OPTIONS'
export const SET_CURRENT_CAT = 'SET_CURRENT_CAT'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SORT_BY_LETTER = 'SORT_BY_LETTER'
export const SORT_BY_GENDER = 'SORT_BY_GENDER'
export const SET_CURRENT_GEN = 'SET_CURRENT_GEN'
export const FIND_BY_SEARCH = 'FIND_BY_SEARCH'
export const SET_LETTER_SEARCH_STATUS = 'SET_LETTER_SEARCH_STATUS'
export const GET_TALENT_BY_ID = 'GET_TALENT_BY_ID'

export const TALENTS_HAVE_ERRORED = 'TALENTS_HAVE_ERRORED'
export const TALENTS_ARE_LOADING = 'TALENTS_ARE_LOADING'
export const TALENTS_FETCHED_SUCCESSFULLY = 'TALENTS_FETCHED_SUCCESSFULLY'

export const SEARCH_FETCHED_SUCCESSFULLY = 'SEARCH_FETCHED_SUCCESSFULLY'
export const SEARCH_IN_PROGRESS = 'SEARCH_IN_PROGRESS'
export const SEARCH_ERRORED = 'SEARCH_ERRORED'

export const CLEAR_FILTER = 'CLEAR_FILTER'
