export const NEXT_PAGE = 'NEXT_PAGE'
export const nextPage = (allVoiceTalents) => {
  return {
    type: NEXT_PAGE,
    payload: {
      ...allVoiceTalents
    }
  }
}

export const PREV_PAGE = 'PREV_PAGE'
export const prevPage = (allVoiceTalents) => ({
  type: PREV_PAGE,
  payload: {
    ...allVoiceTalents
  }
})

export const SORT_BY_LETTER = 'SORT_BY_LETTER'
export const sortByLetter = (letter) => ({
  type: SORT_BY_LETTER,
  payload: letter
})