export const TOGGLE_PLAYLIST = 'TOGGLE_PLAYLIST'
export const togglePlaylist = () => ({
  type: TOGGLE_PLAYLIST
})
export const ADD_TRACK = 'ADD_TRACK'
export const addTrack = (id) => ({
  type: ADD_TRACK,
  payload: id,
})
export const REMOVE_TRACK = 'REMOVE_TRACK'
export const removeTrack = (id) => ({
  type: REMOVE_TRACK,
  payload: id,
})
export const CLEAR_PLAYLIST = 'CLEAR_PLAYLIST'
export const clearPlaylist = () => ({
  type: CLEAR_PLAYLIST
})